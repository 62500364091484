import React from 'react';
import './mint.css';

class Mint extends React.Component {
    renderLabel(props) {
        if(props.transaction) {
            return(<label className='mintAmountETH'>{props.status} <a href={'https://etherscan.io/tx/' + props.transaction.hash} target="_blank">txn</a></label>);
        } else if (props.status) {
            return(<label className='mintAmountETH'>{props.status}</label>);
        } else {
            return (<label className='mintAmountETH'>{parseFloat(props.mintPrice * props.mintNumber).toFixed(3)} ETH { (props.account) && ('via ' + (props.account.slice(0, 6) + '...')) }</label>);
        }
    }

    render() {
        return(
            <div className='mintWrapper'>
                {!this.props.saleLive ? (
                    <label className='mintAmountETH'>mint not live</label>
                ) : (
                    <div><label className='mintLeft'>{this.props.remaining} / {this.props.maxSupply}</label>

                    <div className='mintContainer'>
                        <div className='mintAmountContainer'>
                            <label className='mintAmountNumber'>{this.props.mintNumber}</label>
                            <label className='mintAmountLabel'>z00t</label>
                        </div>
    
                        <div className='mintModifierContainer'>
                            <button className='mintModifierPlus' onClick={() => { this.props.addMint() }}>
                                +
                            </button>
    
                            <button className='mintModifierMinus' onClick={() => { this.props.removeMint() }}>
                                -
                            </button>
                        </div>
    
                        <div className='mintBtnContainer'>
                            <button className='mintBtn' onClick={() => { this.props.mint() }}>
                                <span>mint</span>
                            </button>
                        </div>
                    </div>
    
                    { this.renderLabel(this.props) }
                    </div>
                )}
            </div>
        );
    }
}

export default Mint;