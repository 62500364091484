import React from 'react';
import './mint.css';

class ConnectWallet extends React.Component {
    render() {
        return(
            <div className='connectWalletWrapper'>
                <div className='mintAmountContainer'>
                    <button className='connectWalletBtn' onClick={() => { this.props.connectWallet() }}>
                        connect
                    </button>
                </div>  
            </div>
        )
    }
}

export default ConnectWallet;
