import React, {} from 'react';
import './App.css';
import Smoke from './smoke';
import Mint from './mint';
import ConnectWallet from './connectWallet';
import machine from './assets/slot-machine2x.png';
import followBtn from './assets/follow-btn.png';
import chatBtn from './assets/chat-btn.png';
import etherscanBtn from './assets/etherscan-btn.png';

import {ethers} from 'ethers';
import contract from './contracts/z00tsNFT.abi.json';

const MINT_PRICE = 0.033;
const TOTAL_MINT = 4444;
const Z00T_CONTRACT = "0xe8468f9a220884dc6bcc691f9b5484dc524ad9cc";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentAccount: false, numberOfMints: 1, mintStatus: '', remaining: 1 };

    this.addMint = this.addMint.bind(this);
    this.removeMint = this.removeMint.bind(this);
    this.mint = this.mint.bind(this);
    this.connectWallet = this.connectWallet.bind(this);
  }

  componentDidMount() {
    if(window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        this.connectWallet();
      });
    }
  }

  addMint() {
    if(this.state.numberOfMints >= 20) return;
    this.setState({ status: '', transaction: null, numberOfMints: this.state.numberOfMints + 1 })
  }

  removeMint() {
    if(this.state.numberOfMints <= 1) return;
    this.setState({ status: '', transaction: null, numberOfMints: this.state.numberOfMints - 1 })
  }

  leftToMint = async() => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer   = provider.getSigner();
      const z00ts    = new ethers.Contract(Z00T_CONTRACT, contract.abi, signer);
      const totalSupply = await z00ts.totalSupply();
      const mintStarted = await z00ts.saleIsActive();
      this.setState({saleLive: mintStarted, remaining: totalSupply.toString()});
  }

  mint = async () => {
    if(!this.isMetamaskInstalled) {
      alert('MetaMask crypto wallet is required to mint. Install it then try again');
      return;
    }

    if(await this.isOnCorrectChain() == false) {
      alert('Connect to Ethereum network to mint.');
      return;
    }

    if(!this.isWalletConnected()) {
      await this.connectWallet();
    }

    this.setState({status: 'Processing...'});

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer   = provider.getSigner();
      const z00ts    = new ethers.Contract(Z00T_CONTRACT, contract.abi, signer);

      // send txn
      let mintTx     = await z00ts.mint(this.state.numberOfMints, { 
         value: ethers.utils.parseEther( (this.state.numberOfMints * MINT_PRICE).toString() )
      });

      // wait for mine
      this.setState({status: 'Processing...', transaction: mintTx});
      await mintTx.wait();
        
      // complete
      this.setState({status: 'Complete √', transaction: mintTx});
      
    } catch(err) {
      switch(err.code) {
        case 'ACTION_REJECTED': this.setState({status: 'Txn denied'}); break
        case 'INSUFFICIENT_FUNDS': this.setState({status: 'Low balance'}); break
        default:  this.setState({status: err.code});
      }
    }
  }

  isMetamaskInstalled() {
    return window.ethereum;
  }

  isWalletConnected() {
    return this.state.currentAccount;
  }

  isOnCorrectChain = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let network = await provider.getNetwork();
    if(network.chainId !== 1) { return false } else { return true; }
  }

  connectWallet = async () => {
    if(!this.isMetamaskInstalled) {
      alert('MetaMask crypto wallet is required to mint. Install it then try again');
      return;
    }

    try {
      const account = await window.ethereum.request({method: 'eth_requestAccounts'});
      if(await this.isOnCorrectChain() == false) {
        alert('Connect to Ethereum network to mint.');
        return;
      }
      
      await this.leftToMint();

      this.setState({currentAccount: account[0]}, () => {
        
      });
    } catch(err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div className="App">
        <div className="AppBackground"></div>

        <Smoke />

        <div className="z00tHeaderWrapper">
          <a href="https://twitter.com/z00tsNFT" target="_blank"><img src={followBtn} alt="Follow z00ts on Twitter"/></a>
          {/* <a href="https://discord.gg/PyhJcDUvgk" target="_blank"><img src={chatBtn} alt="Join z00ts discord"/></a> */}
          <a href="https://etherscan.io/address/0xe8468F9a220884DC6bcc691f9b5484Dc524Ad9CC#code" target="_blank" style={{marginLeft: "5px", opacity: "0.666"}}><img src={etherscanBtn} alt="z00ts smart contract"/></a>
        </div>

        <div className="z00tMachineWrapper">

          {!this.state.currentAccount ? (
            <ConnectWallet connectWallet={this.connectWallet} />
          ) : (
            <Mint 
              addMint={this.addMint} 
              removeMint={this.removeMint} 
              mint={this.mint}
              mintNumber={this.state.numberOfMints} 
              mintPrice={MINT_PRICE}
              remaining={this.state.remaining}
              saleLive={this.state.saleLive}
              maxSupply={TOTAL_MINT}
              account={this.state.currentAccount}
              status={this.state.status}
              transaction={this.state.transaction}
            />
          )}
          <img className="z00tMachine" src={machine} alt="z00t machine" />

        </div>

      </div>
    );
  }
}

export default App;
